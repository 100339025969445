var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sta-wrapper"},[_c('b-container',{attrs:{"fluid":""}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('iq-card',{scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [_c('h4',{staticClass:"card-title"},[_vm._v("Select Indicators")])]},proxy:true},{key:"body",fn:function(){return _vm._l((_vm.medicalChar),function(value,key){return _c('span',{key:key},[_c('span',[_c('b-button',{staticClass:"mb-3 mr-1",style:(key==_vm.selected?
                  {
                    background: _vm.ch.get(key).midColor,
                    borderColor: _vm.ch.get(key).midColor,
                    color:'#fff'
                  }:
                  {
                    color: _vm.ch.get(key).midColor,
                    borderColor: _vm.ch.get(key).midColor
                  }),attrs:{"pill":"","variant":"outline-primary","id":"selector-button"},on:{"click":function($event){return _vm.click($event, key)},"mouseenter":function($event){return _vm.mouseEnter($event, key)},"mouseleave":function($event){return _vm.mouseLeave($event, key)}}},[_vm._v(" "+_vm._s(value)+" ")])],1)])})},proxy:true}])}),_c('iq-card',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"title"},[_c('h4',{staticClass:"card-title"},[_vm._v("Indicators Analysis")]),_c('el-switch',{attrs:{"active-text":"show standard"},model:{value:(_vm.show_standard),callback:function ($$v) {_vm.show_standard=$$v},expression:"show_standard"}})],1),_c('statistics-chart2',{attrs:{"data":_vm.data2,"min":_vm.min,"max":_vm.max,"show_standard":_vm.show_standard}})]},proxy:true}])})],1),_c('el-col',{attrs:{"span":11}},[_c('iq-card',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"title"},[_c('h4',{staticClass:"card-title"},[_vm._v("Indicators Analysis")])]),_c('statistics-chart1',{attrs:{"data":_vm.data1}})]},proxy:true}])})],1),_c('el-col',{attrs:{"span":13}},[_c('iq-card',{directives:[{name:"show",rawName:"v-show",value:(this.selected),expression:"this.selected"}],scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [_c('h4',{staticClass:"card-title"},[_vm._v("Feature Importance Variation Learned by AICare")])]},proxy:true},{key:"body",fn:function(){return [_c('el-image',{attrs:{"src":_vm.pic_src}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture-outline"})])])]},proxy:true}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }